
import { defineComponent, ref, onMounted, computed } from 'vue';
import X from '@/components/web3/x.vue';
import useTokens from '../../composables/PolarisFinance/useTokens';
import { useRoute } from 'vue-router';
import useWeb3 from '@/services/web3/useWeb3';
import useStake from '../../composables/PolarisFinance/useStake';
import { parseFixed } from '@ethersproject/bignumber';

import useTransactions from '@/composables/useTransactions';
import { TransactionResponse } from '@ethersproject/providers';
import useEthers from '../../composables/useEthers';

/**
 * STATE
 */
const textInput = ref<HTMLInputElement>();
/**
 * LIFECYCLE
 */
onMounted(() => {
  textInput.value?.focus();
});
export default defineComponent({
  components: {
    X,
  },
  props: {
    isVisible: Boolean,
    depositBol: Boolean,
    balance: { type: String, default: '0' },
    token: String,
    address: { type: String, default: '' },
  },
  setup(props, { emit }) {
    const { getProvider } = useWeb3();

    const route = useRoute();
    const { addTransaction } = useTransactions();
    const { txListener } = useEthers();

    const txHandler = (tx: TransactionResponse): void => {
      addTransaction({
        id: tx.hash,
        type: 'tx',
        action: 'stake',
        summary: 'deposit lp to xpolarRewardPool',
      });
    };

    async function deposit(amount: string) {
      const formatedAmount = parseFixed(amount, 18);
      console.log(formatedAmount);
      const { deposit } = useStake();
      console.log(props.address);
      const tx = await deposit(props.address, formatedAmount, getProvider());
      txHandler(tx);
      txListener(tx, {
        onTxConfirmed: () => {
          emit('close');
          emit('update');
        },
        onTxFailed: () => {},
      });
    }

    async function withdraw(amount: string) {
      const formatedAmount = parseFixed(amount, 18);
      const { withdraw } = useStake();
      const tx = await withdraw(props.address, formatedAmount, getProvider());
      txHandler(tx);
      txListener(tx, {
        onTxConfirmed: () => {
          emit('close');
          emit('update');
        },
        onTxFailed: () => {},
      });
    }

    return {
      deposit,
      withdraw,
    };
  },

  data() {
    return {
      inputValue: '0',
    };
  },
  methods: {
    maxBalance() {
      this.inputValue = this.balance;
    },
  },

  emits: ['close', 'update'],
});
