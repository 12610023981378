
import { computed, defineComponent, reactive, toRefs, ref } from 'vue';
import { useRoute } from 'vue-router';
import { sunriseDefinitions } from './config';
import useBreakpoints from '@/composables/useBreakpoints';

import polarImg from './polar.svg';
import orbitalImg from './orbital.svg';
import uspImg from './usp.svg';
import ethernalImg from './ethernal.svg';
import binarisImg from './binaris.svg';
import tripolarImg from './tripolar.svg';

import PbondImg from './bond/Pbond.svg';
import ObondImg from './bond/Obond.svg';
import USPbondImg from './bond/USPbond.svg';
import EbondImg from './bond/Ebond.svg';
import BbondImg from './bond/Bbond.svg';

import useWeb3 from '@/services/web3/useWeb3';

import useStake from '../composables/PolarisFinance/useStake';
import StakeModal from './pool/StakeModal.vue';
import useTokens from '@/composables/useTokens';
import useTransactions from '@/composables/useTransactions';
import useEthers from '@/composables/useEthers';
import { TransactionResponse } from '@ethersproject/providers';
import { BigNumber } from 'ethers';
import {
  BigNumberToString,
  sunriseNameToAddress,
  SPOLAR,
  getDisplayBalance,
} from '../composables/PolarisFinance/utils';
interface PoolPageData {
  id: string;
}

export default defineComponent({
  components: { StakeModal },
  setup() {
    const route = useRoute();
    const { isMobile, isDesktop } = useBreakpoints();

    const { account, getProvider } = useWeb3();

    const logo = {
      polar: polarImg,
      orbital: orbitalImg,
      usp: uspImg,
      ethernal: ethernalImg,
      binaris: binarisImg,
      tripolar: tripolarImg,

      pbond: PbondImg,
      obond: ObondImg,
      uspbond: USPbondImg,
      ebond: EbondImg,
      bbond: BbondImg,
    };
    const data = reactive<PoolPageData>({
      id: route.params.id as string,
    });

    const sunrise = computed(() => {
      for (let sunrise of Object.values(sunriseDefinitions)) {
        if (sunrise.name == data.id) return sunrise;
      }
      if (
        ['OBOND', 'EBOND', 'USPBOND', 'BBOND', 'PBOND'].includes(
          data.id.toUpperCase()
        )
      ) {
        return { name: data.id };
      }
      return undefined;
    });

    const tokenAddress = computed(() => {
      for (let sunrise of Object.values(sunriseDefinitions)) {
        if (sunrise.name == data.id) return sunrise.tokenAddress;
      }
      if (
        ['OBOND', 'EBOND', 'USPBOND', 'BBOND', 'PBOND'].includes(
          data.id.toUpperCase()
        )
      ) {
        for (let sunrise of Object.values(sunriseDefinitions)) {
          if (sunrise.bond == data.id.toUpperCase())
            return sunrise.bondTokenAddress;
        }
      }
      return undefined;
    });
    const isStakeModalVisible = ref(false);
    const isUnstakeModalVisible = ref(false);

    const toggleStakeModal = (value?: boolean) => {
      isStakeModalVisible.value = value ?? !isStakeModalVisible.value;
    };

    const toggleUnstakeModal = (value?: boolean) => {
      isUnstakeModalVisible.value = value ?? !isUnstakeModalVisible.value;
    };

    const { balanceFor } = useTokens();
    const { addTransaction } = useTransactions();
    const { txListener } = useEthers();

    const txHandler = (tx: TransactionResponse): void => {
      addTransaction({
        id: tx.hash,
        type: 'tx',
        action: 'approve',
        summary: 'approve for staking',
      });
    };
    return {
      // data
      ...toRefs(data),
      isMobile,
      isDesktop,
      logo,

      // computed
      sunrise,
      isStakeModalVisible,
      isUnstakeModalVisible,
      toggleStakeModal,
      toggleUnstakeModal,
      tokenAddress,
      account,
      balanceFor,
      txHandler,
      txListener,
      getProvider,
    };
  },
  data() {
    return {
      approved: false,
      DAILY_APR: '-',
      epoch: '-',
      balance: '-',
      earned: '-',
      canWithdraw: false,
      canClaim: false,
      spolarsStaked: '-',
      lastEpochTwap: '-',
      twap: '-',
      printTwap: '-',
      depositedInDollars: '-',
      earnedAmountInDollars: '-',
      nextEpoch: '-',
      APR: '-',
      withdrawTime: '-',
      claimTime: '-',
      TVL: '-',
      tokenWalletBalance: '0',
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      const { balance, pendingShare, walletBalance, isApproved } = useStake();
      this.balance = await balance(this.tokenAddress, this.account);
      this.earned = BigNumberToString(
        await pendingShare(this.tokenAddress, this.account),
        14,
        4
      );
      this.approved = await isApproved(this.tokenAddress, this.account);
    },
    async approve() {
      const { approve } = useStake();
      const tx = await approve(this.tokenAddress, this.getProvider());
      this.txHandler(tx);
      this.txListener(tx, {
        onTxConfirmed: () => {
          this.fetchData();
        },
        onTxFailed: () => {},
      });
    },
    async claim() {
      const { withdraw } = useStake();
      const tx = await withdraw(
        this.tokenAddress,
        BigNumber.from(0),
        this.getProvider()
      );
      this.txHandler(tx);
      this.txListener(tx, {
        onTxConfirmed: () => {
          this.fetchData();
        },
        onTxFailed: () => {},
      });
    },
    async withdrawEverything() {
      const { withdrawAll } = useStake();
      const tx = await withdrawAll(
        this.tokenAddress,
        this.account,
        this.getProvider()
      );
      this.txHandler(tx);
      this.txListener(tx, {
        onTxConfirmed: () => {
          this.fetchData();
        },
        onTxFailed: () => {},
      });
    },
  },
  watch: {
    async account(newAccount, oldAccount) {
      await this.fetchData();
    },
  },
});
